import "./ReturnToRecipes.css"
import { Link } from "react-router-dom";


function ReturnToRecipes() {
    return (
        <Link to={`/recipes`} className="recipe-link">
            <div className="return-to-recipes">
                <svg viewBox="0 0 24 24" focusable="false" className="icon-small"><path fill="currentColor" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg>
                <p className="return-text">Back to Recipes</p>
            </div>
        </Link>
    );
}

export default ReturnToRecipes