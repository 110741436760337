import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Home, Recipes, RecipeDetail } from './pages';

function App() {
  return (
    <Router>
      <Switch>
        {/* Home Page */}
        <Route path="/" exact component={Home} />

        {/* Recipes List */}
        <Route path="/recipes" exact component={Recipes} />

        {/* Recipe Detail with Dynamic ID */}
        <Route path="/recipes/:recipeId" component={RecipeDetail} />

        {/* Redirect Unknown Routes to Home */}
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
