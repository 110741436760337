import React from "react";
import yellowbutton from "./yellow-button.png"
import "./BuyMeACoffeeButton.css"

function BuyMeACoffeeButton() {
    return (
        <a href="https://buymeacoffee.com/jackydaam" target="_blank" rel="noopener noreferrer">
            <img
                alt="Buy Me a Coffee Widget"
                src={yellowbutton}
                className="donate-button"
            />
        </a>
    );
}

export default BuyMeACoffeeButton;