import "./Navbar.css"
import BuyMeACoffeeButton from "../Donation/BuyMeACoffeeButton"
import { NavHashLink as NavLink } from 'react-router-hash-link';

import {
    FaHome,
    FaInstagram,
    FaTiktok,
    FaYoutube
} from 'react-icons/fa';

function Navbar({ primaryColor='#000000', position='relative' }) {
    return (
        <nav className="navbar-container" style={{ '--position': position }}>
            {(
                <ul className="navbar-nav" style={{ '--primary-color': primaryColor }}>
                    <li className="navbar-home navbar-item">
                    <NavLink
                        to='/'>
                        {/* <FaHome
                            className='navbar-home navbar-icons'
                            style={{ color: "black" }}
                            aria-label='Github'
                        /> */}
                        JD's Kitchen
                    </NavLink>
                </li>
                </ul>
                
                
            )}
            <ul className="navbar-nav" style={{ '--primary-color': primaryColor }}>
                <li className="navbar-item">
                    <NavLink
                        to='/'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                       Home
                    </NavLink>
                </li>

                <li className="navbar-item">
                    <NavLink
                        to='/recipes'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                       Recipes
                    </NavLink>
                </li>
                {/* <li className="navbar-item">
                    <NavLink
                        to='#menu'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                       View Menu
                    </NavLink>
                </li> */}
                {/* <li className="navbar-item">
                    <NavLink
                        to='/gallery'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                        Photo Gallery
                    </NavLink>
                </li> */}
                
                <li className="navbar-item">
                    <a data-formkit-toggle="f40d977138" href="https://jackydam.ck.page/f40d977138">Newsletter</a>                
                </li>

                <a href="https://www.youtube.com/@jackydaam/?utm_source=jd_website&utm_medium=website" target="_blank" rel="noopener noreferrer">
                    <FaYoutube
                        className='navbar-social navbar-icons'
                        style={{ color: primaryColor }}
                        aria-label='Youtube'
                    />
                </a>
                <a href="https://www.instagram.com/jackydaam/?utm_source=jd_website&utm_medium=website" target="_blank" rel="noopener noreferrer">
                    <FaInstagram
                        className='navbar-social navbar-icons'
                        style={{ color: primaryColor }}
                        aria-label='Instagram'
                    />
                </a>
                <a href="https://www.tiktok.com/@jackydaam/?utm_source=jd_website&utm_medium=website" target="_blank" rel="noopener noreferrer">
                    <FaTiktok
                        className='navbar-social navbar-icons'
                        style={{ color: primaryColor }}
                        aria-label='Tiktok'
                    />
                </a>


                <BuyMeACoffeeButton />
            </ul>

        </nav>
    );
}

export default Navbar