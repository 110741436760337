import React from "react";
import { Link } from "react-router-dom";
import { Globalalert, Navbar, Footer, Contact, ReturnToRecipes } from '../../components'


import "./Recipes.css"

const recipesList = [
    { 
        id: "inn-n-out-cheese-burger", 
        title: "Inn-N-Out Cheese Burger",
        image: "https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695218%2FbabishImages%2Fundefined-286946.png&w=828&q=75",
        date: "xxx"
    },
    { 
        id: "inn-n-out-animal-style-fries", 
        title: "Inn-N-Out Animal Style Fries",
        image: "https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695218%2FbabishImages%2Fundefined-286946.png&w=828&q=75",
        date: "xxx"
    },
    { 
        id: "five-guys-cheese-burger", 
        title: "Five Guys Cheese Burger",
        image: "https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695218%2FbabishImages%2Fundefined-286946.png&w=828&q=75",
        date: "xxx"
    },
    { 
        id: "mcdonalds-chicken-nugget", 
        title: "Mcdonalds Chicken Nugget",
        image: "https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695218%2FbabishImages%2Fundefined-286946.png&w=828&q=75",
        date: "xxx"
    },
    { 
        id: "panda-express-orange-chicken", 
        title: "Panda Express Orange Chicken",
        image: "https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695218%2FbabishImages%2Fundefined-286946.png&w=828&q=75",
        date: "xxx"
    },
    { 
        id: "costco-chicken-bake", 
        title: "Costco Chicken Bake",
        image: "https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695218%2FbabishImages%2Fundefined-286946.png&w=828&q=75",
        date: "xxx"
    },
    { 
        id: "jerseys-mike-rosemary-parmesan-bread", 
        title: "Jersey's Mike Rosemary Parmesan Bread",
        image: "https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695218%2FbabishImages%2Fundefined-286946.png&w=828&q=75",
        date: "xxx"
    },
    { 
        id: "subways-italian-herbs-and-cheese-sandwich", 
        title: "Subway’s Italian Herbs & Cheese Sandwich",
        image: "https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695218%2FbabishImages%2Fundefined-286946.png&w=828&q=75",
        date: "xxx"
    },
    { 
        id: "christmas-sugar-cookies", 
        title: "Christmas-Sugar-Cookies",
        image: "https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695218%2FbabishImages%2Fundefined-286946.png&w=828&q=75",
        date: "xxx"
    },
    { 
        id: "sourdough-bread", 
        title: "Sourdough Bread",
        image: "https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695218%2FbabishImages%2Fundefined-286946.png&w=828&q=75",
        date: "xxx"
    },
    
    // Add other recipes here...
];

function RecipesPage() {
    return (
        <div id="recipes-page" className="site">
            <Navbar primaryColor="#000000"/>
            
            <ul className="recipes-list">
                {recipesList.map((recipe) => (
                    <li className="recipe-item" key={recipe.id}>
                        <Link to={`/recipes/${recipe.id}`} className="recipe-link">
                            <img className="recipe-image" src={recipe.image} />
                            <h2 className="recipe-header">{recipe.title}</h2>
                            <h3 className="recipe-created-date">{recipe.date}</h3>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default RecipesPage;
