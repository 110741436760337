import React from 'react'
import Swal from 'sweetalert2'
import "./Contact.css"

function Contact() {
    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        formData.append("access_key", "17950dbf-8123-4f5a-abda-2a52318a1cf7");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
            },
            body: json
        }).then((res) => res.json());

        if (res.success) {
            Swal.fire({
                title: "Success!",
                text: "Message sent successfully!",
                icon: "success"
              });
        }
    };

    return (
        <section id="contact" className="contact">
            <form className='contact-form' onSubmit={onSubmit}>
                <h2>Contact Me</h2>
                <div className="input-box">
                    <label>Full Name</label>
                    <input type="text" className="field" placeholder='Enter Your name' name='name' required />
                </div>
                <div className="input-box">
                    <label>Email Address</label>
                    <input type="email" className="field" placeholder='Enter Your email' name='email' required />
                </div>
                <div className="input-box">
                    <label>Your Message</label>
                    <textarea name='message' className="field message" placeholder='Enter Your message' required ></textarea>
                </div>
                <button type="submit">Send Message</button>
            </form>
        </section>
    );
}

export default Contact;