import "./RecipeDetail.css"

import React from "react";
import { useParams } from "react-router-dom";
import { Globalalert, Navbar, Footer, Contact, ReturnToRecipes } from '../../components'

import {
    FaPrint,
    FaDownload
} from 'react-icons/fa';

const difficultyDefinitions = {
    Wolf: "Any potential recipe that poses a difficulty to an unknown degree.",
    Tiger: "A difficult recipe for an average home cook.",
    Demon: "A difficult recipe for a professional chef.",
    Dragon: "A difficult recipe requiring many unique ingredients not readily available in local grocery stores.",
    God: "A recipe so difficult, it might just destroy your dinner plans — or at least your kitchen..",
};

const recipeData = {
    "inn-n-out-cheese-burger": {
        youtubeID: "4J3dftx07o0",
        videoCaption: 'Featuring YouTube Video: Inn-N-Out Cheese Burger at Home | But Better by JD’s Kitchen',
        title: "Inn-N-Out Cheese Burger",
        date: "November 15, 2024",
        duration: "90 Minutes",
        difficulty: "Tiger",
        "ingredients": {
            "Burger Buns": [
            "5g Yeast",
            "8g Sugar",
            "200g Luke Warm Water",
            "400g Bread Flour",
            "2g Salt"
            ],
            "Secret Spread Sauce": [
            "4 tbsp Ketchup",
            "2 tbsp Unsalted Butter",
            "2 tbsp Worcestershire Sauce",
            "1/2 Yellow Onion",
            "1/2 Dill Pickle",
            "2 cups Mayonnaise",
            "Salt & Pepper to Taste"
            ],
            "Burger": [
            "100g 80/20 Ground Beef",
            "Iceberg Lettuce",
            "Tomato",
            "Yellow Onion",
            "Mild Cheddar Cheese",
            "Salt & Pepper to Taste"
            ]
        },
        "steps": {
            "Burger Buns": [
            "Start by combining lukewarm whole milk with instant yeast. Whisk together. Let sit at room temperature for 10 minutes.",
            "In the bowl of a stand mixer, combine and whisk together bread flour, fine sea salt, and granulated sugar.",
            "For the rue, combine bread flour and water whisking together and beat together over medium heat until thick.",
            "Using a dough hook, dry ingredients on low speed and add in the yeast/milk mixture followed by an egg and egg yolk and the cooled rue (flour mixture). Mix 2-5 minutes.",
            "Add in softened butter 1 Tbsp at a time and mix until thoroughly combined. 2-3 minutes.",
            "Place the dough ball into a greased bowl and cover with lightly greased plastic wrap. Let rise 1.5-2 hours.",
            "Degas the dough, and on a lightly floured surface divide into 6 equal portions.",
            "Fold the sides over and flip the seam side down and roll into a ball.",
            "Place on a parchment-lined baking sheet. Cover buns with a damp towel or inverted baking sheet. Proof for 45 minutes.",
            "Brush buns with egg wash. Bake at 400F for 15-17 minutes. Brush with melted butter."
            ],
            "Secret Sauce Recipe": [
            "Start by dicing the onions and adding unsalted butter to a small saucepan over medium heat.",
            "Add in the onions once the butter is bubbling along with a pinch of sugar and salt.",
            "Cook over medium heat for 30 minutes.",
            "In a medium bowl, combine and whisk together mayo, ketchup, finely chopped chives, Worcestershire sauce, and a pinch of salt.",
            "Add finely diced dill pickles and caramelized onions. Mix together."
            ],
            "Burger": [
            "Start by searing the sliced onions over medium-high heat.",
            "In a cast iron skillet over medium-high heat, add in the burger ball and press down using a spatula. Season with salt.",
            "Sear the burger for 2-3 minutes on both sides. Base with melted butter, garlic, and rosemary.",
            "Melt a slice of cheddar cheese to one patty and cheddar jack cheese to the other.",
            "Place griddled onion on the bottom patty, top the onion with the top patty.",
            "Slice the buns and toast over melted butter.",
            "Add a generous amount of burger sauce to both buns followed by sliced tomato and iceberg lettuce on the bottom bun.",
            "Carefully add the burger patties and finish with the top bun."
            ]
        }
    },
    // Add other recipes here...
};

function RecipeDetail() {
    const { recipeId } = useParams();
    console.log("recipeId:", recipeId);
    const recipe = recipeData[recipeId];

    if (!recipe) {
        return (
            <div id="no-recipe-page" className="site">
                <Navbar primaryColor="#000000" />
                <section className="error-section">
                    <div className="error-container">
                        <h1 className="error-title">Oops! Looks like this Recipe is Missing</h1>
                        <p className="error-message">Maybe it’s not quite ready yet, or I just made a typo. Either way, don't worry – there are plenty of other tasty recipes to discover back in my recipes catalog.</p>
                        <div className="btn-primary-container">
                            <a href="/recipes" className="btn-primary">View All Recipes</a>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    const { youtubeID, videoCaption, title, date, duration, difficulty, ingredients, steps } = recipe;

    const handlePrint = () => {
        window.print();
    };

    return (
        <div id="recipe-page" className="site">
            <Navbar primaryColor="#000000" />

            <ReturnToRecipes className="return-to-recipes"/>

            <section className="recipe-section">
                <header className="recipe-header-container">
                    <div className="recipe-header-title-container">
                        <h1 className="recipe-header-title">{title}</h1>
                        <FaPrint className="fa-icon" onClick={handlePrint}/>
                    </div>
                    <p className="recipe-header-subheader">
                        {date} | {duration} |{" "}
                        <span className="tooltip">
                            {difficulty}
                            <span className="tooltip-text">
                                Disaster Level ({difficulty}): {difficultyDefinitions[difficulty]}
                            </span>
                        </span>
                    </p>
                </header>

                <div className="video-container">
                    <iframe
                        className="youtube-video-web"
                        src={`https://www.youtube.com/embed/${youtubeID}`}
                        title={title}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <div className='youtube-video-print'>
                        <img className="youtube-video-thumbnail" src={`https://img.youtube.com/vi/${youtubeID}/maxresdefault.jpg`} alt="YouTube thumbnail"/>
                    </div>
                </div>
                <figcaption className="youtube-video-caption">{videoCaption}</figcaption>

                <div className="recipe-content-container">
                    <div className="recipe-ingredients-column">
                        <h1 className="recipe-ingredients-title">Ingredients:</h1>
                        {Object.keys(ingredients).map((section) => (
                            <ul className="recipe-ingredient-list" key={section}>
                                <h2 className="recipe-item-header">{section}:</h2>
                                {ingredients[section].map((item, index) => (
                                    <li className="recipe-item-ingredient" key={index}>{item}</li>
                                ))}
                            </ul>
                        ))}
                    </div>
                    <div className="recipe-instructions-column">
                        <h1 className="recipe-instructions-title">Preparations</h1>
                        {Object.keys(steps).map((section) => (
                            <ol className="recipe-instructions-list" key={section}>
                                <h2 className="recipe-item-header">{section}:</h2>
                                {steps[section].map((step, index) => (
                                    <li className="recipe-item-instructions" key={index}>{index + 1}.) {step}</li>
                                ))}
                            </ol>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default RecipeDetail;