import "./Home.css"

import React, { useEffect } from 'react';

import { Globalalert, Navbar, Footer, Contact } from '../../components'
import { Helmet } from 'react-helmet'

function Home() {
    const globalAlertItems = {
        body: "🔔 Cast your vote in the straw poll below for the recipe you would like to see featured in my next YouTube video 🔔",
        ctaText: "",
        ctaURL: ""
    }

    return (
        <div id="homepage" className="site">
            <Helmet>
                <title>Jacky Dam</title>
            </Helmet>
            
            {/* <Globalalert globalAlertItems={ globalAlertItems }/> */}
            <Navbar primaryColor="#fff" position='absolute'/>

            <section className="hero-banner">
                <div className="hero-container">
                    <div className="hero-content">
                        <h1>Cooking Content Fresh Out the Oven</h1>
                        <p>Releasing videos on my Youtube Channel every Sunday at 10am PST</p>
                    </div>
                    <div className="hero-image-container">
                        <img className="hero-image" src="https://www.babi.sh/_next/image?url=%2Fimg%2Fhome%2Fmessy-babish.jpeg&w=3840&q=75" loading="lazy"/>
                    </div>
                </div>
            </section>

            <div className="recent-posts">
                <h1 className="recent-posts-title">Recent Posts</h1>
                <div className="recent-posts-container">
                    <ul className="recent-posts-list">
                        <li className="recent-post-item">
                            <a href="/recipes/vietnamese-seafood-pasta">
                                <img className="recent-post-img" src="https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1701664080%2Fkto1etnwcxylvi1921nv.webp&w=828&q=75"/>
                            </a>
                            <h2 className="recent-post-header">Vietnamese Seafood Pasta</h2>
                            <h3 className="recent-post-created-date">Sept 19, 2024</h3>
                        </li>
                        <li className="recent-post-item">
                            <a href="/recipes/tom-yom-spaghetti">
                                <img className="recent-post-img" src="https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1680589997%2Fpspwvu6udkxcl4fqlfxh.webp&w=828&q=75"/>
                            </a>
                            <h2 className="recent-post-header">Tom Yum Spaghetti</h2>
                            <h3 className="recent-post-created-date">Sept 27, 2024</h3>
                        </li>
                        <li className="recent-post-item">
                            <a href="/recipes/chinese-style-fried-rice">
                                <img className="recent-post-img" src="https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695218%2FbabishImages%2Fundefined-286946.png&w=828&q=75"/>
                                </a>
                                <h2 className="recent-post-header">Chinese-Style Fried Rice</h2>
                                <h3 className="recent-post-created-date">Dec 1, 2024</h3>
                        </li>
                    </ul>
                    <div className="btn-primary-container">
                        <a href="/recipes" className="btn-primary">View All Recipes</a>
                    </div>
                </div>
            </div>

            {/* <div id="menu" className="featured-product-section">
                <h1>Featured Video</h1>
                
            </div> */}

            {/* <div className="featured-catalog-section">
                <h1 className="featured-catalog-title">Featured Recipes</h1>
                <div className="grid-container">
                    <div className="grid-primary">
                        <div className="grid grid-1x2 first-grid">
                            <div className="grid-item item-1">
                                <a href="http://www.google.com" target="_blank">
                                    <img className="featured-post-img" src="https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1725472664%2Fo1xkl6c29o1zkbk7ckni.webp&w=828&q=75"/>
                                </a>
                            </div>
                            <div className="grid-item item-2">
                                <a href="http://www.google.com" target="_blank">
                                    <img className="featured-post-img" src="https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1690352392%2Fkfj7peybgqxukvxts1ot.webp&w=828&q=75"/>
                                </a>
                            </div>
                        </div>
                        <div className="grid grid-1x2 second-grid">
                            <div className="grid-item item-3">
                                <a href="http://www.google.com" target="_blank">
                                    <img className="featured-post-img" src="https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1690484522%2Fzjr8bsbzdipdeb62evtq.webp&w=828&q=75"/>
                                </a>
                            </div>
                            <div className="grid-item item-4">
                                <a href="http://www.google.com" target="_blank">
                                    <img className="featured-post-img" src="https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695549%2FbabishImages%2Fundefined-359574.png&w=828&q=75"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="grid-secondary">
                        <div className="grid grid-1x2 secondary-grid">
                            <div className="grid-item item-5">
                                <a href="http://www.google.com" target="_blank">
                                    <img className="featured-post-img" src="https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1680343867%2Fersvctalsr97j0gpoa1p.webp&w=828&q=75"/>
                                </a>
                            </div>
                            <div className="grid-item item-6">
                                <a href="http://www.google.com" target="_blank">
                                    <img className="featured-post-img" src="https://www.babi.sh/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdd27yihoo%2Fimage%2Fupload%2Fc_fill%2Car_1%3A1%2Fv1681695737%2FbabishImages%2Fundefined-872033.png&w=828&q=75"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="recent-post-btn-container">
                        <a href="" className="btn-primary">View All Recipes</a>
                    </div>
            </div> */}


            <Contact />

            {/* <Footer /> */}

        </div >
    )
}

export default Home